<template>
  <div>
    <h2 class="question-title with-subtext">Let’s get started</h2>
    <p class="sub-text group-mb">
      Our doctors will help prescribe what’s right for you. Share your email so
      we can save your answers. We won’t fill up your inbox!
    </p>
    <b-row>
      <b-col cols="12">
        <div role="group" class="group-mb">
          <label for="email" class="input-label">Email Address*</label>
          <b-form-input
            id="email"
            class="fs-mask onboarding-input"
            type="email"
            v-model="email"
            autocomplete="off"
            :state="emailState"
            required
          />
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col cols="12" lg="6">
        <div role="group" class="group-mb">
          <label for="password" class="input-label">Password*</label>
          <b-form-input
            id="password"
            class="fs-mask onboarding-input"
            v-model="password"
            type="password"
            :state="passwordState"
            autocomplete="off"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Password must be a minimum of 8 characters and a maximum of 50.
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col cols="12" lg="6">
        <div role="group" class="group-mb">
          <label for="cpassword" class="input-label">Confirm Password*</label>
          <b-form-input
            id="cpassword"
            class="fs-mask onboarding-input"
            v-model="cpassword"
            type="password"
            :state="cpasswordState"
            autocomplete="off"
            required
            @keyup.enter="register"
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Password do not match
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col cols="12">
        <b-button
          pill
          block
          class="mb-3 mt-3"
          variant="info"
          :disabled="buttonDisabled"
          @click="register"
        >
          Agree and continue
        </b-button>

        <div class="d-block mt-3">
          <small class="d-block mt-3"
            >Already have an account?
            <router-link to="/login">Sign In</router-link></small
          >
        </div>
        <div class="d-block text-center mt-3 mb-4">
          <div class="d-none d-lg-block mt-2"></div>
          <small class="text-muted">
            <b-icon icon="lock-fill"></b-icon> Any information you submit is
            encrypted within our secure platform.
          </small>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// libs
import VueCookies from "vue-cookies";

// components
import formValidation from "@/components/form/mixins";

// services
import { AuthService } from "@/services/auth.service";
import { OnboardingService } from "@/services/onboarding.service";

// utils
import { isValidEmail } from "@/utils/string";

export default {
  mixins: [formValidation],
  props: {
    eligibilityParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      email: "",
      password: "",
      cpassword: "",
      buttonDisabled: false,
    };
  },
  methods: {
    async fbqTrack(auth) {
      try {
        // FB Pixel Tracking
        fbq("init", "348219289912097", {
          external_id: auth.userid,
          extern_id: auth.userid,
        });
        // fbq("track", "PageView");
        let pageViewBody = { userid: auth.userid };
        OnboardingService.onboardingEvent("PageView", pageViewBody);
        fbq(
          "trackCustom",
          "AccountCreated",
          {
            external_id: auth.userid,
            extern_id: auth.userid,
            ...(await this.$store.dispatch("getFBQParams")),
            state: this.eligibilityParams.state,
          },
          {
            eventID: auth.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async register() {
      try {
        if (this.password != this.cpassword) {
          return this.$emit("showAlert", "The password fields do not match.");
        }

        if (this.email.trim().length == 0 || this.password.trim().length == 0) {
          return this.$emit("showAlert", "Please fill in all the fields.");
        }

        if (!this.emailState || !isValidEmail(this.email)) {
          return this.$emit("showAlert", "Please input a valid email.");
        }

        this.buttonDisabled = true;

        const data = {
          email: this.email,
          password: this.password,
          role: "PATIENT",
          state: this.eligibilityParams.state,
        };

        // * Onboarding Flow Split
        let flow = $cookies.get("onboardingflow") || $cookies.get("flow");
        if (flow) {
          switch (flow) {
            case "b":
            case "flowB":
              data.onboardingflow = "flowB";
              break;
            case "a":
            case "flowA":
              data.onboardingflow = "flowA";
              break;
            default:
              data.onboardingflow =
                $cookies.get("onboardingflow") || $cookies.get("flow");
              break;
          }
          $cookies.remove("onboardingflow");
          $cookies.remove("flow");
        }

        // * Referrals
        if ($cookies.get("referralToken")) {
          //data.referralcode = $cookies.get("referralToken");
          this.$set(data, "referralcode", $cookies.get("referralToken"));
        }

        const { data: auth } = await AuthService.signup(data);
        if (auth.idToken) {
          AuthService.setBearer(auth.idToken);
          if (typeof FS !== "undefined") {
            console.log("FS Identify", this.email);
            FS.identify(this.email);
          }

          // * Share a Sale

          let sas_cookie = VueCookies.get("sas_m_awin") || undefined;

          if (sas_cookie != undefined && sas_cookie.clickId != undefined) {
            VueCookies.remove("sas_m_awin");
          }
        }

        // * FB Track
        if (auth.eventid) {
          // * Segment Event
          window.analytics.track(
            "Account Created",
            {
              customerId: auth.userid,
              messageID: auth.eventid,
              ...(await this.$store.dispatch("getDeviceDetailsForContext")),
              ...(await this.$store.dispatch("getVoluumDetails")),
              // ...(await this.$store.dispatch("getFBQParams")),
              email: this.email,
              state: this.eligibilityParams.state,
            },
            {
              referrer: await this.$store.dispatch("getEverflowDetails"),
            }
          );

          localStorage.setItem("authenticatedUserId", auth.userid);
          const userIdPopulated = new Event("userIdPopulated");
          window.dispatchEvent(userIdPopulated);

          // Tatari
          if (tatari) {
            console.log("Tatari Identify: ", auth.userid);
            tatari.identify(`$${[auth.userid]}`);
            console.log("Tatari Track: Account Created");
            tatari.track("Account Created");
          }

          await this.fbqTrack(auth);
        } else if (auth.eventid && process.env.VUE_APP_SERVER != "production") {
          // alert('FB TRACKING AccountCreated: ' + auth.eventid);
          console.log("FB TRACKING AccountCreated");
          console.log("FB TRACKING AccountCreated: " + auth.eventid);
        }

        // * Promocode
        if ($cookies.get("promocode") && !$cookies.get("promocodeExistingID")) {
          const applyCode = await OnboardingService.applyCoupon({
            couponid: $cookies.get("promocode"),
          });
          const getCoupon = await OnboardingService.checkCoupon(
            $cookies.get("promocode")
          );

          if (getCoupon.data.hasOwnProperty("applies_to")) {
            // Skips cookie removal if code applies to Blossom
            if (
              getCoupon.data.applies_to.products[0] != "prod_Jxqgr2Hh9YGwjZ" &&
              getCoupon.data.applies_to.products[0] != "prod_K0rnRpVxfgGP4y"
            ) {
              this.$cookies.remove("promocode");
            }
          } else {
            this.$cookies.remove("promocode");
          }
        }

        // * Fullstory Event
        if (typeof FS != "undefined") {
          FS.event("Account Created");
        }

        let onboardingStartedTimestamp = $cookies.get(
          "onboardingStartedTimestamp"
        );
        const identifyBody = {
          id: auth.userid,
          name: "Onboarding Started",
          value: onboardingStartedTimestamp,
        };

        await OnboardingService.onboardingIdentify(identifyBody);

        this.buttonDisabled = false;
        this.$emit("setId", auth.userid);
        this.$emit("setEmail", this.email);
        this.$emit("setStep", "birthday");
      } catch (err) {
        if (err.message == "An account with the given email already exists.") {
          this.$emit(
            "showEmailAlert",
            `An account with the email ${this.email} already exists. You may sign in or enter a different email address to create a new account.`,
            this.email
          );
        } else {
          if (err.status == 500) {
            this.$store.dispatch("sendErrorLog", {
              step: "Eligibility",
              error_log: {
                message: err,
              },
            });
          }
          this.$emit("showAlert", err.message);
        }
        this.buttonDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hide-arrows {
  -moz-appearance: textfield;
}
</style>
