var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "question-title with-subtext" }, [
        _vm._v("Let’s get started"),
      ]),
      _c("p", { staticClass: "sub-text group-mb" }, [
        _vm._v(
          " Our doctors will help prescribe what’s right for you. Share your email so we can save your answers. We won’t fill up your inbox! "
        ),
      ]),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "group-mb", attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "email" } },
                  [_vm._v("Email Address*")]
                ),
                _c("b-form-input", {
                  staticClass: "fs-mask onboarding-input",
                  attrs: {
                    id: "email",
                    type: "email",
                    autocomplete: "off",
                    state: _vm.emailState,
                    required: "",
                  },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                }),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: "input-live-feedback" } },
                  [_vm._v(" The email must have '@email.com' ")]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "div",
              { staticClass: "group-mb", attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "password" } },
                  [_vm._v("Password*")]
                ),
                _c("b-form-input", {
                  staticClass: "fs-mask onboarding-input",
                  attrs: {
                    id: "password",
                    type: "password",
                    state: _vm.passwordState,
                    autocomplete: "off",
                    required: "",
                  },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                }),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: "input-live-feedback" } },
                  [
                    _vm._v(
                      " Password must be a minimum of 8 characters and a maximum of 50. "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "div",
              { staticClass: "group-mb", attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "cpassword" } },
                  [_vm._v("Confirm Password*")]
                ),
                _c("b-form-input", {
                  staticClass: "fs-mask onboarding-input",
                  attrs: {
                    id: "cpassword",
                    type: "password",
                    state: _vm.cpasswordState,
                    autocomplete: "off",
                    required: "",
                  },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.register.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.cpassword,
                    callback: function ($$v) {
                      _vm.cpassword = $$v
                    },
                    expression: "cpassword",
                  },
                }),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: "input-live-feedback" } },
                  [_vm._v(" Password do not match ")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mb-3 mt-3",
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "info",
                    disabled: _vm.buttonDisabled,
                  },
                  on: { click: _vm.register },
                },
                [_vm._v(" Agree and continue ")]
              ),
              _c("div", { staticClass: "d-block mt-3" }, [
                _c(
                  "small",
                  { staticClass: "d-block mt-3" },
                  [
                    _vm._v("Already have an account? "),
                    _c("router-link", { attrs: { to: "/login" } }, [
                      _vm._v("Sign In"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "d-block text-center mt-3 mb-4" }, [
                _c("div", { staticClass: "d-none d-lg-block mt-2" }),
                _c(
                  "small",
                  { staticClass: "text-muted" },
                  [
                    _c("b-icon", { attrs: { icon: "lock-fill" } }),
                    _vm._v(
                      " Any information you submit is encrypted within our secure platform. "
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }