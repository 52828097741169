var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eligibilityBirthday" },
    [
      _c("h2", { staticClass: "question-title with-subtext" }, [
        _vm._v("When is your birthday?"),
      ]),
      _c("p", { staticClass: "sub-text group-mb" }, [
        _vm._v(
          " Age helps us to determine if you are eligible for treatment. "
        ),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "div",
                { staticClass: "mb-3 pb-1", attrs: { role: "group" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "birthday" } },
                    [_vm._v("Date of birth*")]
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##/##/####",
                        expression: "'##/##/####'",
                      },
                    ],
                    staticClass: "fs-exclude onboarding-input birthdate",
                    attrs: {
                      id: "birthday",
                      placeholder: "MM/DD/YYYY",
                      type: "text",
                      pattern: "[0-9]*",
                      inputmode: "numeric",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.birthday,
                      callback: function ($$v) {
                        _vm.birthday = $$v
                      },
                      expression: "birthday",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-3",
                  attrs: {
                    pill: "",
                    variant: "info",
                    disabled: _vm.buttonDisabled,
                  },
                  on: { click: _vm.submitAnswer },
                },
                [_vm._v("Continue")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showModal
        ? _c("Modal", {
            attrs: { body: _vm.ageModal },
            on: { submitAnswer: _vm.exit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }