var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eligibility-sex" },
    [
      _c("h2", { staticClass: "question-title with-subtext" }, [
        _vm._v(" What was the sex assigned to you at birth? "),
      ]),
      _c("p", { staticClass: "sub-text group-mb" }, [
        _vm._v(
          " Your sex assignment helps us to determine if you are eligible for treatment. "
        ),
      ]),
      _c("w-checkbox", {
        staticClass: "fs-exclude",
        attrs: {
          type: "radio",
          value: "Female",
          label: "Female",
          id: "sex-female",
        },
        on: { change: _vm.submitAnswer },
        model: {
          value: _vm.sex,
          callback: function ($$v) {
            _vm.sex = $$v
          },
          expression: "sex",
        },
      }),
      _c("w-checkbox", {
        staticClass: "fs-exclude",
        attrs: { type: "radio", value: "Male", label: "Male", id: "sex-male" },
        on: { change: _vm.submitAnswer },
        model: {
          value: _vm.sex,
          callback: function ($$v) {
            _vm.sex = $$v
          },
          expression: "sex",
        },
      }),
      _vm.sex
        ? _c(
            "b-button",
            {
              attrs: { pill: "", variant: "info" },
              on: { click: _vm.submitAnswer },
            },
            [_vm._v("Continue")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }