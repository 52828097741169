<template>
  <div class="eligibilitySurgery">
    <b-row>
      <b-col>
        <div class="mb-4">
          <h2 class="mb-3 heading text-primary">
            Have you undergone a surgery that removed your ovaries, and put you
            into menopause?
          </h2>
          <div class="single-answer">
            <w-checkbox
              type="radio"
              id="overianSurgeryYes"
              v-model="surgery"
              value="Yes"
              label="Yes"
              @change="submitAnswer"
            />
            <w-checkbox
              type="radio"
              id="ovarianSurgeryNo"
              v-model="surgery"
              value="No"
              label="No"
              @change="submitAnswer"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      surgery: "",
    };
  },
  methods: {
    submitAnswer() {
      if (this.surgery == "Yes") {
        this.$emit("setParams", "ovariansurgery", true);
        // this.$emit('showLoader', 4);
        this.$emit("setStep", "registration");
      } else {
        this.$emit("setParams", "ovariansurgery", false);
        this.$emit("setStep", "under35");
      }
    },
  },
  async mounted() {
    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        screen: "1.3.1.1-A",
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
        ...(await this.$store.dispatch("getVoluumDetails")),
      },
      {
        referrer: await this.$store.dispatch("getEverflowDetails"),
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
  },
};
</script>

<style></style>
