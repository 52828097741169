<template>
  <div class="eligibility-sex">
    <h2 class="question-title with-subtext">
      What was the sex assigned to you at birth?
    </h2>
    <p class="sub-text group-mb">
      Your sex assignment helps us to determine if you are eligible for
      treatment.
    </p>
    <w-checkbox
      class="fs-exclude"
      v-model="sex"
      type="radio"
      value="Female"
      label="Female"
      id="sex-female"
      @change="submitAnswer"
    ></w-checkbox>
    <w-checkbox
      class="fs-exclude"
      v-model="sex"
      type="radio"
      value="Male"
      label="Male"
      id="sex-male"
      @change="submitAnswer"
    ></w-checkbox>
    <b-button v-if="sex" pill variant="info" @click="submitAnswer"
      >Continue</b-button
    >
  </div>
</template>

<script>
export default {
  props: ["age", "onboardingflow", "preAnswer"],
  data() {
    return {
      sex: "",
    };
  },
  methods: {
    async submitAnswer() {
      this.$emit("setParams", "gender", this.sex);
      if (this.sex == "Male") {
        this.$emit("setStep", "male");

        window.analytics.track(
          "Onboarding Screen Viewed",
          {
            screen: "1.3.2-A",
            onboardingflow: this.onboardingflow,
            ...(await this.$store.dispatch("getDeviceDetailsForContext")),
            ...(await this.$store.dispatch("getVoluumDetails")),
          },
          {
            referrer: await this.$store.dispatch("getEverflowDetails"),
            context: await this.$store.dispatch("geDeviceDetails"),
          }
        );
      } else if (this.sex == "Female" && this.age < 35) {
        this.$emit("setStep", "surgery");
        window.analytics.track(
          "Onboarding Screen Viewed",
          {
            screen: "1.3.1-A",
            onboardingflow: this.onboardingflow,
            ...(await this.$store.dispatch("getDeviceDetailsForContext")),
            ...(await this.$store.dispatch("getVoluumDetails")),
          },
          {
            referrer: await this.$store.dispatch("getEverflowDetails"),
            context: await this.$store.dispatch("geDeviceDetails"),
          }
        );
      } else {
        // this.$emit('showLoader', 4);
        this.$emit("checkToken");
        this.$emit("setStep", "registration");
      }
    },
  },
  async mounted() {
    if (this.preAnswer) {
      this.sex = this.preAnswer;
    }

    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        screen: "1.3-A",
        onboardingflow: this.onboardingflow,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
        ...(await this.$store.dispatch("getVoluumDetails")),
      },
      {
        referrer: await this.$store.dispatch("getEverflowDetails"),
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
  },
};
</script>

<style></style>
